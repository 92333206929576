import {useEffect, useId, useState} from "react";
import mainLogo from "../../../assets/images/global/Inbio_logo.png";
import {Link, useNavigate} from "react-router-dom";
import {useQuery} from "react-query";
import {accountService} from "../../../_services";
import {BiCopyAlt} from "react-icons/bi";
import {GoSignOut} from "react-icons/go";

const Header = (props) => {
    let navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    useEffect(() => {
        document.title = `Sellinbio - ${props.title}`;
    }, [props.title]);

    let randomID = useId();
    let baseURL = 'https://sellinb.io/';
    let shownURL = 'sellinb.io/';

    const {isLoading, data} = useQuery(randomID, () => accountService.getUserData());
    const userData = data || {"data": []}

    useEffect(() => {
        if (!isLoading && userData.full_name) {
            setFullName(userData.full_name)
        }
    }, [fullName, userData]);

    const handleCopy = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                // alert('Link copied to clipboard!');
            })
            .catch((error) => {
                console.error('Error copying link to clipboard: ', error);
            });
    };

    if (!localStorage.getItem('full_name')) {
        localStorage.setItem('full_name', fullName);
    }

    const logout = () => {
        accountService.logout()
            .then(res => {
                if (res.data.status === 200) {
                    localStorage.removeItem('token_inbio');
                    localStorage.removeItem('full_name');
                    navigate("/auth/login")
                }
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="the-main-header px-3 bg-white sticky-top d-flex align-items-center justify-content-between">
            <div className="d-none d-lg-block page-title fw-bold main-dark-color">{props.title}</div>
            <div className="d-lg-none header-logo-holder">
                <Link to="/user/dashboard"
                      className="logo-holder d-flex align-items-center justify-content-center h-100">
                    <img src={mainLogo} alt="Sellinbio" className="logo-img"/>
                </Link>
            </div>
            <div className="profile-link-holder d-flex align-items-center">
                <Link to={`${baseURL}${userData.username}`} target="_blank" className="profile-link fw-bold-600">
                    {`${shownURL}${userData.username}`}
                </Link>
                <div className="copy-btn-holder ms-1 ms-lg-2 d-sm-block">
                    <button onClick={() => handleCopy(`${baseURL}${userData.username}`)} type="button"
                            className="profile-link border-0 bg-transparent">
                        <div className="d-none d-lg-flex align-items-center copy-btn px-3 py-1 rounded-3">
                            <div className="fw-bold-500 me-1">
                                Copy
                            </div>
                            <div>
                                <BiCopyAlt size="20"/>
                            </div>
                        </div>
                        <div className="d-lg-none">
                            <BiCopyAlt size="20"/>
                        </div>
                    </button>
                </div>
                <div className="logout-button">
                    <button type="button" onClick={logout}
                            className="nav-link-item d-flex align-items-center border-0 bg-transparent">
                        <GoSignOut size="25" className="text-danger"/>
                    </button>
                </div>
            </div>
        </div>
    );
}
export default Header;