import {Footer, FreeAccessAlert, Header} from "./Private";
import {Link} from "react-router-dom";
import {
    GoProject,
    GoStack,
    GoGear,
    GoCreditCard
} from "react-icons/go";
import {HiOutlineAcademicCap} from "react-icons/hi2";
import {CiBank} from "react-icons/ci";
import {IoBarChartOutline, IoGitMergeOutline} from "react-icons/io5";
import {MdOutlineInsertLink, MdOutlineIntegrationInstructions} from "react-icons/md";

const More = () => {
    return (
        <>
            <Header title="Dashboard"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="main-more-page row bg-white pt-4 rounded-3">
                    <div className="col-4 mb-5">
                        <Link to="/user/store" className="box-holder text-center">
                            <div className="icon-holder mb-2 main-text-color">
                                <div className="icon">
                                    <GoStack size="30"/>
                                </div>
                            </div>
                            <div className="box-title main-dark-color">
                                My Store
                            </div>
                        </Link>
                    </div>
                    <div className="col-4 mb-5">
                        <Link to="/user/income" className="box-holder text-center">
                            <div className="icon-holder mb-2 main-text-color">
                                <div className="icon">
                                    <GoCreditCard size="30"/>
                                </div>
                            </div>
                            <div className="box-title main-dark-color">
                                Income
                            </div>
                        </Link>
                    </div>
                    <div className="col-4 mb-5">
                        <Link to="/user/analytics" className="box-holder text-center">
                            <div className="icon-holder mb-2 main-text-color">
                                <div className="icon">
                                    <GoProject size="30"/>
                                </div>
                            </div>
                            <div className="box-title main-dark-color">
                                Analytics
                            </div>
                        </Link>
                    </div>
                    <div className="col-4 mb-5">
                        <Link to="/user/academy" className="box-holder text-center">
                            <div className="icon-holder mb-2 main-text-color">
                                <div className="icon">
                                    <HiOutlineAcademicCap size="30"/>
                                </div>
                            </div>
                            <div className="box-title main-dark-color">
                                Academy
                            </div>
                        </Link>
                    </div>
                    <div className="col-4 mb-5">
                        <Link to="/user/analytics" className="box-holder text-center">
                            <div className="icon-holder mb-2 main-text-color">
                                <div className="icon">
                                    <IoBarChartOutline size="30"/>
                                </div>
                            </div>
                            <div className="box-title main-dark-color">
                                My Leads
                            </div>
                        </Link>
                    </div>
                    {/*<div className="col-4 mb-5">*/}
                    {/*    <Link to="#" className="box-holder text-center">*/}
                    {/*        <div className="icon-holder mb-2 main-text-color">*/}
                    {/*            <div className="icon">*/}
                    {/*                <CiBank size="30"/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="box-title main-dark-color">*/}
                    {/*            MRR Bank*/}
                    {/*        </div>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                    {/*<div className="col-4 mb-5">*/}
                    {/*    <Link to="/user/analytics" className="box-holder text-center">*/}
                    {/*        <div className="icon-holder mb-2 main-text-color">*/}
                    {/*            <div className="icon">*/}
                    {/*                <IoGitMergeOutline size="30"/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="box-title main-dark-color">*/}
                    {/*            Affiliate*/}
                    {/*        </div>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                    <div className="col-4 mb-5">
                        <Link to="/user/settings" className="box-holder text-center">
                            <div className="icon-holder mb-2 main-text-color">
                                <div className="icon">
                                    <GoGear size="30"/>
                                </div>
                            </div>
                            <div className="box-title main-dark-color">
                                Settings
                            </div>
                        </Link>
                    </div>
                    <div className="col-4 mb-5">
                        <Link to="/user/settings" className="box-holder text-center">
                            <div className="icon-holder mb-2 main-text-color">
                                <div className="icon">
                                    <MdOutlineInsertLink size="30"/>
                                </div>
                            </div>
                            <div className="box-title main-dark-color">
                                Integrations
                            </div>
                        </Link>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
};

export default More;