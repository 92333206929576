import {accountService, userOrderService} from "../../../_services";
import tranzakLogo from "../../../assets/images/global/tranzak_logo.png";

const UpgradeTranzakPayment = ({product_name, access_type, price, payment_method}) => {
    const productData = {
        product: {
            amount: price,
            currencyCode: "USD",
            paymentMethod: 'card',
            description: product_name,
            payerNote: 'Sellinbio Subscription',
            cancelUrl: "",
            returnUrl: 'https://sellinb.io/user/thank-you',
        }
    }

    const handlePayClickToPay = () => {
        userOrderService.PayOrderWithTranzak(productData)
            .then(async res => {
                if (res.status === 200) {
                    if (res.data.data) {
                        const planData = {
                            subscription_id: res.data.data.requestId,
                            plan: product_name,
                            access_type: access_type,
                            payment_method: payment_method,
                            price: price,
                            status: "not_paid",
                        }
                        try {
                            await accountService.upgradeUser(planData)
                                .then(res1 => {
                                    if (res1.data.status === 201) {
                                        if (res.data.data.requestId) {
                                            window.location.href = res.data.data.links.paymentAuthUrl
                                        }
                                    }
                                })
                                .catch(error => console.log(error))
                        } catch (error) {
                            console.error("Error: ", error);
                        }
                    }
                }
            })
            .catch(error => console.log(error))
    }

    return (
        <div className="pay-button">
            <button type="button"
                    onClick={() => handlePayClickToPay()}
                    className="w-100 my-2 border-0 py-3 rounded-3">
                <span className="d-inline-block small fw-bold-500 me-2">
                    Pay With Mobile Wallet
                </span>
                <img src={tranzakLogo} className="tranzak-logo" width="100" alt=""/>
            </button>
        </div>
    );
}

export default UpgradeTranzakPayment;