import {Footer, Header, PaypalKeysSettings, StripeKeysSettings, TranzakKeysSettings} from "./Admin";

const KeysSettings = () => {

    return(
        <>
            <Header title="Admin - Keys Settings"/>
            <div
                className="admin-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="admin-settings-page bg-white p-3 rounded-3">
                    <div className="bg-white mb-3 p-2 p-lg-3 rounded-3 row m-0">
                        <div className="paypal-keys-setting mb-3">
                            <PaypalKeysSettings/>
                        </div>
                        <div className="tranzak-keys-setting mb-3">
                            <TranzakKeysSettings/>
                        </div>
                        <div className="stripe-keys-setting mb-3">
                            <StripeKeysSettings/>
                        </div>
                    </div>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default KeysSettings;