import {Routes, Route} from "react-router-dom";
import {
    PLayout,
    Dashboard,
    Error,
    Store,
    StoreProducts,
    PdfBooks,
    Income,
    Analytics,
    Academy,
    ExternalLinks,
    DigitalDownloads,
    BookAppointment,
    AskMeAnyThing,
    Settings,
    More,
    PricingPlans,
    UserThankYou
} from "./Private";

const PrivateRouter = () => {
    return (
        <Routes>
            <Route element={<PLayout/>}>
                <Route index element={<Dashboard/>}/>
                <Route index path="/" element={<Dashboard/>}/>
                <Route index path="/dashboard" element={<Dashboard/>}/>
                <Route index path="/store" element={<Store/>}/>
                <Route index path="/income" element={<Income/>}/>
                <Route index path="/analytics" element={<Analytics/>}/>
                <Route index path="/academy" element={<Academy/>}/>
                <Route index path="/settings" element={<Settings/>}/>
                <Route index path="/pricing" element={<PricingPlans/>}/>
                <Route index path="/thank-you" element={<UserThankYou/>}/>
                <Route index path="/more" element={<More/>}/>
                <Route index path="/store/store-products" element={<StoreProducts/>}/>
                <Route index path="/store/pdf-books" element={<PdfBooks/>}/>
                <Route index path="/store/external-links/:pid?" element={<ExternalLinks/>}/>
                <Route index path="/store/digital-downloads/:pid?" element={<DigitalDownloads/>}/>
                <Route index path="/store/book-appointment/:pid?" element={<BookAppointment/>}/>
                <Route index path="/store/ask-me-anything/:pid?" element={<AskMeAnyThing/>}/>

                <Route path="*" element={<Error/>}/>
            </Route>
        </Routes>
    );
}

export default PrivateRouter;