import {PayPalButtons} from "@paypal/react-paypal-js";
import {accountService} from "../../../_services";
import {useNavigate} from "react-router-dom";

const UpgradePaypalPaymentButton = ({product_name, price, access_type, payment_method}) => {

    let navigate = useNavigate();

    const createOrder = (data) => {
        return fetch("https://api.sellinb.io/payments/paypal/create-paypal-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                product:
                    {
                        description: product_name,
                        price: price,
                    },
            }),
        })
            .then((response) => response.json())
            .then((order) => order.id);
    };

    const onApprove = (data) => {
        const ORDER_ID = data.orderID;
        // Order is captured on the server and the response is returned to the browser
        return fetch(`https://api.sellinb.io/payments/paypal/capture-paypal-order/${ORDER_ID}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then(async data => {
                if (data.status === "COMPLETED") {
                    const planData = {
                        subscription_id: ORDER_ID,
                        plan: product_name,
                        access_type: access_type,
                        payment_method: payment_method,
                        price: price,
                        status: "paid",
                    }
                    try {
                        await accountService.upgradeUser(planData)
                            .then(res => {
                                if (res.data.status === 200) {
                                    accountService.saveToken(res.data.jwt);
                                    localStorage.setItem('SELLINBIO_SUBSCRIPTION_ID', ORDER_ID);
                                    navigate('/user/thank-you', {replace: true});
                                }
                            })
                            .catch(error => console.log(error))
                    } catch (error) {
                        console.error("Error: ", error);
                    }
                }
            });
    };

    return (
        <PayPalButtons
            createOrder={(data, actions) => createOrder(data, actions)}
            onApprove={(data, actions) => onApprove(data, actions)}
            style={{layout: "horizontal"}}
        />
    );
};

export default UpgradePaypalPaymentButton;