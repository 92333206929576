import React, {useEffect, useState} from "react";
import {adminSettingService} from "../../../_services";

const StripeKeysSettingsForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParamsValues] = useState({
        promise_key: "",
        secret_key: "",
    });

    useEffect(() => {
        setParamsValues({
            promise_key: props.promise_key,
            secret_key: props.secret_key,
        });
    }, [props]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("promise_key", params.promise_key);
            formData.append("secret_key", params.secret_key);
            formData.append("settings_id", props.settings_id);
            await adminSettingService.updateStripeKeysSettings(formData);
            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
        } catch (error) {
            console.error("Error Updating Stripe Keys Settings:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="main-form-holder bg-white light-shadow rounded-2 p-2 p-md-3">
            <div className="fw-bold-600 small text-center mb-3">Edit Stripe Keys Settings</div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="form-group col-md-6 mb-3">
                            <label className="mb-1 small fw-bold-500" htmlFor="promise_key">
                                Promise Key:
                            </label>
                            <div className="key-holder d-flex align-items-center">
                                <div className="key-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm py-2"
                                        placeholder="Stripe Promise Key"
                                        value={params.promise_key}
                                        onChange={onChange}
                                        name="promise_key"
                                        id="promise_key"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <label className="mb-1 small fw-bold-500" htmlFor="secret_key">
                                Secret Key:
                            </label>
                            <div className="product_name-holder d-flex align-items-center">
                                <div className="product_name-input flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm py-2"
                                        placeholder="Stripe Secret Key"
                                        value={params.secret_key}
                                        onChange={onChange}
                                        name="secret_key"
                                        id="secret_key"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-3 mt-md-4">
                        <button type="button" disabled={isLoading}
                                className="btn btn-sm btn-outline-secondary border-0 py-1 px-3 rounded-2 me-3"
                                onClick={setFormHidden}>
                            Cancel
                        </button>
                        <button type="submit"
                                className="btn btn-sm btn-outline-secondary text-white border-0 py-1 px-5 rounded-2 main-button-color"
                                disabled={isLoading}>
                            {isLoading ? "Loading..." : "Save"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default StripeKeysSettingsForm;