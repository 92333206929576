import {Footer, FreeAccessAlert, GetReadyToPay, Header, UpgradePaypalPaymentButton} from "./Private";
import {BiCheckCircle} from "react-icons/bi";
import React, {useId, useState} from "react";
import {useQuery} from "react-query";
import {accountService} from "../../_services";
import {ThreeDots} from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";

const PricingPlans = () => {
    let randomID = useId();
    const [modalShow, setModalShow] = useState(false);
    const [planName, setPlanName] = useState("");
    const [planAccess, setPlanAccess] = useState("");
    const [planPrice, setPlanPrice] = useState(0);

    const handleClose = () => setModalShow(false);
    const handleShow = (planAccess, planName, planPrice) => {
        setPlanName(planName)
        setPlanAccess(planAccess)
        setPlanPrice(planPrice)
        setModalShow(true);
    }

    const {isLoading, data} = useQuery(randomID, () => accountService.getUserAccess());
    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    return (
        <>
            <Header title="Pricing Plans"/>
            <FreeAccessAlert/>
            <div
                className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="p-3 rounded-3 mb-3 main-pricing-page">
                    <div className="text-center black-text-color fw-bold-600 fs-5 px-2 mb-3">
                        Choose Your Pricing Plan
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-xxl-4 mb-3">
                            <div className="light-shadow bg-white rounded-3 pricing-box p-3">
                                <div className="plan-header text-center mb-2">
                                    <div className="plan-title main-dark-color fw-bold fs-4">Basic</div>
                                    <div className="plan-subtitle text-black-50 small">Starter</div>
                                    <div className="plan-price-holder d-flex justify-content-center align-items-center">
                                        <div className="currency-symbol text-black-50 small">$</div>
                                        <div className="plan-price black-text-color fw-bold">0.00</div>
                                        <div className="plan-period text-black-50 small">/Month</div>
                                    </div>
                                </div>
                                <div className="horizontal-line mx-auto"/>
                                <div className="plan-body my-3">
                                    <div className="plan-features">
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Access to Promote Free Products
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Very Low Commission on Sales (40%)
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Limited Links and Product Promotion <br className="d-sm-none"/>(Max 3)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="horizontal-line mx-auto"/>
                                <div className="plan-footer mt-4">
                                    <div className="plan-button text-center">
                                        {data.access_type === "free_access" ? (
                                            <button type="button" disabled={true}
                                                    className="main-bg-light-color rounded-3 border-0 px-5 py-2 small fw-bold-600 text-white">
                                                Current Plan
                                            </button>
                                        ) : (
                                            <button type="button"
                                                    onClick={() => handleShow('free_access', 'Free', 0)}
                                                    className="main-button-color rounded-3 border-0 px-5 py-2 small fw-bold-600 text-white">
                                                Choose Plan
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4 mb-3">
                            <div className="light-shadow bg-white rounded-3 pricing-box p-3">
                                <div className="plan-header text-center mb-2">
                                    <div className="plan-title main-dark-color fw-bold fs-4">Pro</div>
                                    <div className="plan-subtitle text-black-50 small">Better results</div>
                                    <div className="plan-price-holder d-flex justify-content-center align-items-center">
                                        <div className="currency-symbol text-black-50 small">$</div>
                                        <div className="plan-price black-text-color fw-bold">19.99</div>
                                        <div className="plan-period text-black-50 small">/Month</div>
                                    </div>
                                </div>
                                <div className="horizontal-line mx-auto"/>
                                <div className="plan-body my-3">
                                    <div className="plan-features">
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Low percentage on Sales (50%)
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Access to Basic Courses
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Access to Promote Digital Products<br/>
                                                (All Products Excluding VIP Courses)
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Unlimited Link and Product Promotion
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                24/7 Live Support
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="horizontal-line mx-auto"/>
                                <div className="plan-footer mt-4">
                                    <div className="plan-button text-center">
                                        {data.access_type === "pro_access" ? (
                                            <button type="button" disabled={true}
                                                    className="main-bg-light-color rounded-3 border-0 px-5 py-2 small fw-bold-600 text-white">
                                                Current Plan
                                            </button>
                                        ) : (
                                            <button type="button"
                                                    onClick={() => handleShow('pro_access', 'Pro', 19.99)}
                                                    className="main-button-color rounded-3 border-0 px-5 py-2 small fw-bold-600 text-white">
                                                Choose Plan
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4 mb-3">
                            <div className="light-shadow bg-white rounded-3 pricing-box p-3">
                                <div className="plan-header text-center mb-2">
                                    <div className="plan-title main-dark-color fw-bold fs-4">Premium</div>
                                    <div className="plan-subtitle text-black-50 small">Full features plus extras</div>
                                    <div className="plan-price-holder d-flex justify-content-center align-items-center">
                                        <div className="currency-symbol text-black-50 small">$</div>
                                        <div className="plan-price black-text-color fw-bold">49.99</div>
                                        <div className="plan-period text-black-50 small">/Month</div>
                                    </div>
                                </div>
                                <div className="horizontal-line mx-auto"/>
                                <div className="plan-body my-3">
                                    <div className="plan-features">
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Access to VIP Courses
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Access to VIP Promote Products (HOT Niches)<br/>
                                                (Master Resell Rights Complete Bundle)<br/>
                                                Course Price - From $39.99<br/>
                                                (Making Money Online Products)<br/>
                                                (Faceless Digital Marketing Bundle)
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Access to VIP Academy<br/>
                                                (Courses on how to promote Make Money Online Products)
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Higher Commissions on Sales (60%)
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Unlimited Link and Product Promotion
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                24/7 Live Support
                                            </div>
                                        </div>
                                        <div className="feature mb-2 d-flex align-items-start">
                                            <div className="icon-holder d-flex align-items-center me-2">
                                                <BiCheckCircle size="17" className="main-text-color"/>
                                            </div>
                                            <div className="feature-text gray-text-color small">
                                                Dedicated Account Manager
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="horizontal-line mx-auto"/>
                                <div className="plan-footer mt-4">
                                    <div className="plan-button text-center">
                                        {data.access_type === "premium_access" ? (
                                            <button type="button" disabled={true}
                                                    className="main-bg-light-color rounded-3 border-0 px-5 py-2 small fw-bold-600 text-white">
                                                Current Plan
                                            </button>
                                        ) : (
                                            <button type="button"
                                                    onClick={() => handleShow('premium_access', 'Premium', 49.99)}
                                                    className="main-button-color rounded-3 border-0 px-5 py-2 small fw-bold-600 text-white">
                                                Choose Plan
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        show={modalShow}
                        onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Upgrade to {planName} Membership
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="main-custom-tabs-holder">
                            <GetReadyToPay
                                product_name={planName}
                                access_type={planAccess}
                                price={planPrice}
                            />
                        </Modal.Body>
                    </Modal>
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
}

export default PricingPlans;