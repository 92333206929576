import React, {useEffect, useState} from "react";
import {accountService} from "../../../_services";

const TrackingKeysIntegrations = () => {
    const [refresh, setRefresh] = useState(false);

    const [oldValues, setOldValues] = useState({
        facebook_pixel: "",
        tracking_code: "",
    });
    const [params, setParamsValues] = useState({
        facebook_pixel: "",
        tracking_code: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const trackingKeysData = await accountService.getUserTrackingKeysInfo();
                if (trackingKeysData.TrackingKeys) {
                    setParamsValues({
                        facebook_pixel: trackingKeysData.TrackingKeys.facebook_pixel || '',
                        tracking_code: trackingKeysData.TrackingKeys.tracking_code || '',
                    });
                    setOldValues({
                        facebook_pixel: trackingKeysData.TrackingKeys.facebook_pixel || '',
                        tracking_code: trackingKeysData.TrackingKeys.tracking_code || '',
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // setIsLoading(false);
            } finally {
                // setIsLoading(false);
            }
        };

        fetchData();
    }, [refresh]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmitUpdateTrackingKeys = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("facebook_pixel", params.facebook_pixel);
            formData.append("tracking_code", params.tracking_code);
            await accountService.updateTrackingKeysInfo(formData);
            setRefresh(!refresh)
        } catch (error) {
            console.error("Error While Updating Profile Tracking Keys:", error);
        }
    };

    return (
        <div className="p-2 p-lg-3 light-shadow rounded-3 profile-step-two mt-3">
            <form onSubmit={onSubmitUpdateTrackingKeys}>
                <div className="row m-0 flex-column-reverse flex-lg-row align-items-center">
                    <div className="p-2 p-lg-3">
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2"
                                           htmlFor="facebook_pixel">
                                        Facebook Pixel:
                                    </label>
                                    <div className="facebook-pixel-holder d-flex align-items-center">
                                        <div className="facebook-pixel-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.facebook_pixel || ''}
                                                onChange={onChange}
                                                name="facebook_pixel"
                                                id="facebook_pixel"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="form-group">
                                    <label className="text-black-50 small mb-1 fw-bold-500 ps-2"
                                           htmlFor="tracking_code">
                                        Google Analytics Tracking Code:
                                    </label>
                                    <div className="tracking-code-holder d-flex align-items-center">
                                        <div className="tracking-code-input flex-grow-1 position-relative">
                                            <input
                                                type="text"
                                                className="form-control text py-2 custom-input"
                                                value={params.tracking_code || ''}
                                                onChange={onChange}
                                                name="tracking_code"
                                                id="tracking_code"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            disabled={params.facebook_pixel === oldValues.facebook_pixel && params.tracking_code === oldValues.tracking_code}
                            type="submit"
                            className="main-button-color border-0 rounded-3 py-2 px-5 text-white mt-3 small"
                            style={{width: "fit-content"}}>
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default TrackingKeysIntegrations;