import {useEffect, useId, useState} from "react";
import {useQuery} from "react-query";
import {userService} from "../../../_services";
import {ThreeDots} from "react-loader-spinner";
import {Link, useNavigate} from "react-router-dom";
import externalLinkIcon from "../../../assets/images/products/external_link_icon.png";
import askMeAnyThingIcon from "../../../assets/images/products/ask_me_anything_icon.png";
import bookAppointmentIcon from "../../../assets/images/products/book_appointment_icon.png";
import digitalDownloadIcon from "../../../assets/images/products/digital_download_icon.png";
import {CgEye} from "react-icons/cg";
import Modal from "react-bootstrap/Modal";
import checkoutCover from "../../../assets/images/products/checkout_ask_me_cover.webp";
import {BiX} from "react-icons/bi";
import CheckoutOffCanvas from "./CheckoutOffCanvas";

const UserProductsTemplateTwo = (props) => {
    let randomID = useId();
    const {isLoading, data} = useQuery(randomID, () => userService.getUserProducts(username));
    const [modalShow, setModalShow] = useState(false);
    const [productParams, setProductParams] = useState({
        id: "",
        checkout_cover_image: "",
        thumbnail_title: "",
        thumbnail_button: "",
        checkout_description: "",
        checkout_price: "",
        product_type: "",
        checkout_call_to_action_button: "",
    })
    const userProducts = data || {"data": []}
    let username = props.username;

    useEffect(() => {
    }, [props.username]);
    const [offCanvasShow, setOffCanvasShow] = useState(false);

    const handleCloseOffCanvas = () => setOffCanvasShow(false);
    const handleShowOffCanvas = () => {
        handleClose();
        setOffCanvasShow(true)
    };


    const handleClose = () => setModalShow(false);

    const handleShow = (id,
                        checkout_cover_image,
                        thumbnail_title,
                        thumbnail_button,
                        checkout_description,
                        checkout_price,
                        product_type,
                        checkout_call_to_action_button,
                        product_price,
                        checkout_bottom_title
    ) => {
        setProductParams({
            id: id,
            checkout_cover_image: checkout_cover_image,
            thumbnail_title: thumbnail_title,
            thumbnail_button: thumbnail_button,
            checkout_description: checkout_description,
            checkout_price: checkout_price,
            product_type: product_type,
            checkout_call_to_action_button: checkout_call_to_action_button,
            product_price: product_price,
            checkout_bottom_title: checkout_bottom_title
        })
        setModalShow(true);
    }

    const [currency, setCurrency] = useState({
        rate: localStorage.getItem('inbio_chosen_currency') || 1,
        code: localStorage.getItem('inbio_chosen_currency_name') || 'USD'
    });
    const [convertedPrices, setConvertedPrices] = useState([]);

    useEffect(() => {
        const initialCurrency = {
            rate: localStorage.getItem('inbio_chosen_currency') || 1,
            code: localStorage.getItem('inbio_chosen_currency_name') || 'USD'
        };
        setCurrency(initialCurrency);
    }, [props.refresh]);

    useEffect(() => {
        const updateCurrency = () => {
            const updatedCurrency = {
                rate: localStorage.getItem('inbio_chosen_currency') || 1,
                code: localStorage.getItem('inbio_chosen_currency_name') || 'USD'
            };
            setCurrency(updatedCurrency);
        };

        window.addEventListener('storage', updateCurrency);
        return () => {
            window.removeEventListener('storage', updateCurrency);
        };
    }, []);

    useEffect(() => {
        if (data && data.length > 0) {
            const newPrices = data.map(product => {
                let formattedPrice = '';

                // Ensure product.checkout_price is valid
                if (product.checkout_price == null) {
                    return 'FREE';
                }

                // Ensure currency.rate is valid
                const rate = currency.rate ? parseFloat(currency.rate) : 1;

                // Calculate and format the price
                if (rate !== 1) {
                    const convertedPrice = product.checkout_price / rate;
                    formattedPrice = convertedPrice.toLocaleString(undefined, {
                        style: 'currency',
                        currency: currency.code,
                        maximumFractionDigits: 0 // Round to the nearest whole number
                    });
                } else {
                    formattedPrice = product.checkout_price.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'USD', // Assuming default currency is USD
                        maximumFractionDigits: 0 // Round to the nearest whole number
                    });
                }

                return formattedPrice !== 'FREE' ? formattedPrice : 'FREE';
            });

            setConvertedPrices(newPrices);
        }
    }, [currency, data, props.refresh]);


    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <ThreeDots
                    height={100}
                    width={100}
                    radius={5}
                    color="#683BD1"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>);
    }

    let baseURL = 'https://api.sellinb.io';

    return (
        <div className="user-products-section row m-0">
            {userProducts.length > 0 ? (
                userProducts.map((product, index) => (
                    <div className="col-lg-6 col-xl-4 m-0 p-0 px-lg-2" key={product.id}>
                        {product.thumbnail_style_option === 'callout' ? (
                            <div className="callout-layout my-2 light-shadow">
                                <div className="layout-content position-relative rounded-3 p-3">
                                    <div className="row">
                                        <div className="col-5">
                                            <div
                                                className="me-3 d-flex justify-content-center align-items-center rounded-2">
                                                {productParams.checkout_cover_image ? (
                                                    <>
                                                        {productParams.product_type === 'external_link' && (
                                                            <img className="cover-img img-fluid"
                                                                 src={`${baseURL}/thedss/images/ex_li_pr/${productParams.checkout_cover_image}`}
                                                                 alt="External Link Icon"/>
                                                        )}
                                                        {productParams.product_type === 'ask_me_anything' && (
                                                            <img className="cover-img img-fluid"
                                                                 src={`${baseURL}/thedss/images/as_me_an_pr/${productParams.checkout_cover_image}`}
                                                                 alt="Ask Me Any Thing Icon"/>
                                                        )}
                                                        {productParams.product_type === 'book_appointment' && (
                                                            <img className="cover-img img-fluid"
                                                                 src={`${baseURL}/thedss/images/bo_ap_pr/${productParams.checkout_cover_image}`}
                                                                 alt="Book Appointment Icon"/>
                                                        )}
                                                        {productParams.product_type === 'digital_download' && (
                                                            <img className="cover-img img-fluid"
                                                                 src={`${baseURL}/thedss/images/di_do_pr/${productParams.checkout_cover_image}`}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                    </>
                                                ) : (
                                                    <img src={checkoutCover} alt="Cover Image"
                                                         className="cover-img img-fluid"/>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-7 ps-0">
                                            <div className="info-holder w-100">
                                                <div className="title fw-bold-600 mb-1 black-text-color">
                                                    {product.thumbnail_title}
                                                </div>
                                                <div className="subtitle small gray-text-color">
                                                    {product.thumbnail_subtitle}
                                                </div>
                                                {product.checkout_description && (
                                                    <div className="subtitle small gray-text-color"
                                                         dangerouslySetInnerHTML={{
                                                             __html:
                                                                 `${product.checkout_description.substring(0, 70)}`
                                                         }}>
                                                    </div>
                                                )}
                                                {product.product_type !== 'external_link' && (
                                                    <div className="price fw-bold-500 mb-1 light-text-color fs-5 mt-2">
                                                        {convertedPrices[index]}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="link-holder mt-3">
                                        {product.product_type === 'external_link' ? (
                                            <Link to={`${product.thumbnail_url}`}
                                                  className="d-inline-block w-100 link-button text-white rounded-3 p-3 text-center fw-bold-500">
                                                {product.thumbnail_button}
                                            </Link>
                                        ) : (
                                            <Link
                                                onClick={() => handleShow(
                                                    product.id,
                                                    product.checkout_cover_image,
                                                    product.thumbnail_title,
                                                    product.thumbnail_button,
                                                    product.checkout_description,
                                                    convertedPrices[index],
                                                    product.product_type,
                                                    product.checkout_call_to_action_button,
                                                    product.checkout_price,
                                                    product.checkout_bottom_title
                                                )}
                                                to={`/${username}/${product.product_type === 'admin_ebook' ? "ebook/" : ""}${product.id}`}
                                                className="d-inline-block w-100 link-button text-white rounded-3 p-3 text-center fw-bold-500">
                                                {product.thumbnail_button}
                                            </Link>
                                        )}
                                    </div>
                                    {product.product_type !== 'external_link' && (
                                        <div
                                            className="overlay-holder position-absolute w-100 h-100 start-0 top-0 c-pointer d-flex align-items-center justify-content-center"
                                            onClick={() => handleShow(
                                                product.id,
                                                product.checkout_cover_image,
                                                product.thumbnail_title,
                                                product.thumbnail_button,
                                                product.checkout_description,
                                                convertedPrices[index],
                                                product.product_type,
                                                product.checkout_call_to_action_button,
                                                product.checkout_price,
                                                product.checkout_bottom_title
                                            )}>
                                            <div className="d-flex align-items-center text-white">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <CgEye size="25" className="fw-bold"/>
                                                </div>
                                                <div className="fw-bold ps-2">View</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="button-layout my-2 light-shadow">
                                {product.product_type === 'external_link' ? (
                                    <Link to={`${product.thumbnail_url}`}
                                          className="layout-content rounded-3 p-3 d-inline-block w-100">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="prod-icon-holder me-3 d-flex justify-content-center align-items-center rounded-2">
                                                {product.thumbnail_icon_image ? (
                                                    <>
                                                        {product.product_type === 'external_link' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/ex_li_pr/${product.thumbnail_icon_image}`}
                                                                 alt="External Link Icon"/>
                                                        )}
                                                        {product.product_type === 'ask_me_anything' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/as_me_an_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Ask Me Any Thing Icon"/>
                                                        )}
                                                        {product.product_type === 'book_appointment' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/bo_ap_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Book Appointment Icon"/>
                                                        )}
                                                        {product.product_type === 'digital_download' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/di_do_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                        {product.product_type === 'admin_ebook' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/ebooks-images/${product.thumbnail_icon_image}`}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {product.product_type === 'external_link' && (
                                                            <img className="rounded-2"
                                                                 src={externalLinkIcon}
                                                                 alt="External Link Icon"/>
                                                        )}
                                                        {product.product_type === 'ask_me_anything' && (
                                                            <img className="rounded-2"
                                                                 src={askMeAnyThingIcon}
                                                                 alt="Ask Me Any Thing Icon"/>
                                                        )}
                                                        {product.product_type === 'book_appointment' && (
                                                            <img className="rounded-2"
                                                                 src={bookAppointmentIcon}
                                                                 alt="Book Appointment Icon"/>
                                                        )}
                                                        {product.product_type === 'digital_download' && (
                                                            <img className="rounded-2"
                                                                 src={digitalDownloadIcon}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                        {product.product_type === 'admin_ebook' && (
                                                            <img className="rounded-2"
                                                                 src={digitalDownloadIcon}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="info-holder w-100">
                                                <div className="title fw-bold-600 mb-1 black-text-color">
                                                    {product.thumbnail_title}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ) : (
                                    <div
                                        onClick={() => {
                                            handleShow(
                                                product.id,
                                                product.checkout_cover_image,
                                                product.thumbnail_title,
                                                product.thumbnail_button,
                                                product.checkout_description,
                                                convertedPrices[index],
                                                product.product_type,
                                                product.checkout_call_to_action_button,
                                                product.checkout_price,
                                                product.checkout_bottom_title
                                            )
                                        }}
                                        className="layout-content rounded-3 p-3 d-inline-block w-100 position-relative">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="prod-icon-holder me-3 d-flex justify-content-center align-items-center rounded-2">
                                                {product.thumbnail_icon_image ? (
                                                    <>
                                                        {product.product_type === 'external_link' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/ex_li_pr/${product.thumbnail_icon_image}`}
                                                                 alt="External Link Icon"/>
                                                        )}
                                                        {product.product_type === 'ask_me_anything' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/as_me_an_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Ask Me Any Thing Icon"/>
                                                        )}
                                                        {product.product_type === 'book_appointment' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/bo_ap_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Book Appointment Icon"/>
                                                        )}
                                                        {product.product_type === 'digital_download' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/images/di_do_pr/${product.thumbnail_icon_image}`}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                        {product.product_type === 'admin_ebook' && (
                                                            <img className="rounded-2"
                                                                 src={`${baseURL}/thedss/ebooks-images/${product.thumbnail_icon_image}`}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {product.product_type === 'external_link' && (
                                                            <img className="rounded-2"
                                                                 src={externalLinkIcon}
                                                                 alt="External Link Icon"/>
                                                        )}
                                                        {product.product_type === 'ask_me_anything' && (
                                                            <img className="rounded-2"
                                                                 src={askMeAnyThingIcon}
                                                                 alt="Ask Me Any Thing Icon"/>
                                                        )}
                                                        {product.product_type === 'book_appointment' && (
                                                            <img className="rounded-2"
                                                                 src={bookAppointmentIcon}
                                                                 alt="Book Appointment Icon"/>
                                                        )}
                                                        {product.product_type === 'digital_download' && (
                                                            <img className="rounded-2"
                                                                 src={digitalDownloadIcon}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                        {product.product_type === 'admin_ebook' && (
                                                            <img className="rounded-2"
                                                                 src={digitalDownloadIcon}
                                                                 alt="Digital Download Icon"/>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div
                                                className="info-holder d-flex align-items-center justify-content-between w-100">
                                                <div className="title fw-bold-600 mb-1 black-text-color">
                                                    {product.thumbnail_title}
                                                </div>
                                                {product.product_type !== 'external_link' && (
                                                    <div className="price fw-bold mb-1 light-text-color fs-5">
                                                        {convertedPrices[index]}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="overlay-holder position-absolute w-100 h-100 start-0 top-0 c-pointer d-flex align-items-center justify-content-center">
                                            <div className="d-flex align-items-center text-white">
                                                <div
                                                    className="d-flex justify-content-center align-items-center">
                                                    <CgEye size="25" className="fw-bold"/>
                                                </div>
                                                <div className="fw-bold ps-2">View</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <Modal
                            show={modalShow}
                            onHide={handleClose}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            scrollable={true}
                        >
                            <Modal.Body className="main-custom-tabs-holder p-0">
                                <div className="cover-holder">
                                    {productParams.checkout_cover_image ? (
                                        <div className="position-relative">
                                            {productParams.product_type === 'external_link' && (
                                                <img className="cover-img rounded-top-2 img-fluid"
                                                     src={`${baseURL}/thedss/images/ex_li_pr/${productParams.checkout_cover_image}`}
                                                     alt="External Link Icon"/>
                                            )}
                                            {productParams.product_type === 'ask_me_anything' && (
                                                <img className="cover-img rounded-top-2 img-fluid"
                                                     src={`${baseURL}/thedss/images/as_me_an_pr/${productParams.checkout_cover_image}`}
                                                     alt="Ask Me Any Thing Icon"/>
                                            )}
                                            {productParams.product_type === 'book_appointment' && (
                                                <img className="cover-img rounded-top-2 img-fluid"
                                                     src={`${baseURL}/thedss/images/bo_ap_pr/${productParams.checkout_cover_image}`}
                                                     alt="Book Appointment Icon"/>
                                            )}
                                            {productParams.product_type === 'digital_download' && (
                                                <img className="cover-img rounded-top-2 img-fluid"
                                                     src={`${baseURL}/thedss/images/di_do_pr/${productParams.checkout_cover_image}`}
                                                     alt="Digital Download Icon"/>
                                            )}
                                            <div className="position-absolute end-0 top-0 me-1 mt-1">
                                                <div><BiX size="25" color="white" className="c-pointer"
                                                          onClick={handleClose}/></div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="position-relative">
                                            <img src={checkoutCover} alt="Cover Image"
                                                 className="cover-img rounded-top-2 img-fluid"/>
                                            <div className="position-absolute end-0 top-0 me-1 mt-1">
                                                <div><BiX size="25" color="white" className="c-pointer"
                                                          onClick={handleClose}/></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="px-3 info-holder mt-3">
                                    <div className="title main-dark-color fw-bold-600 pb-2">
                                        {productParams.thumbnail_title || productParams.thumbnail_button}
                                    </div>
                                </div>
                                <div
                                    className="px-3 checkout-description-holder small border-top border-bottom border-light-subtle pt-3 pb-2">
                                    <div dangerouslySetInnerHTML={{__html: productParams.checkout_description}}></div>
                                </div>

                            </Modal.Body>
                            <Modal.Footer className="px-3 d-flex align-items-center justify-content-between px-2 py-1">
                                <div className="fw-bold-600 main-dark-color">
                                    {productParams.checkout_price}
                                </div>
                                <div>
                                    {productParams.product_type === 'external_link' ? (
                                        <Link to={`${productParams.thumbnail_url}`}
                                              className="d-inline-block link-as-button text-white rounded-2 py-2 px-3 text-center fw-bold-600">
                                            Buy now
                                        </Link>
                                    ) : (
                                        <button
                                            onClick={handleShowOffCanvas}
                                            className="d-inline-block link-as-button text-white rounded-2 py-2 px-3 text-center fw-bold-600 border-0">
                                            Buy now
                                        </button>
                                    )}
                                </div>
                            </Modal.Footer>
                        </Modal>
                        <CheckoutOffCanvas
                            offCanvasShow={offCanvasShow}
                            handleCloseOffCanvas={handleCloseOffCanvas}
                            productParams={productParams}
                            username={username}
                        />
                    </div>
                ))
            ) : (
                <></>
            )}
        </div>
    );
}
export default UserProductsTemplateTwo;