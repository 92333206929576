import React from "react";
import {orderService} from "../../../_services";
import {useNavigate} from "react-router-dom";

const CheckoutPayHolder = (props) => {
    let navigate = useNavigate();
    const handlePayClick = (payment_method) => {
        if (payment_method === "credit_card") {
            createOrder('not_paid')
        } else if (payment_method === "paypal") {

        }
    }

    // localStorage.setItem('paid_to_username', props.seller_username);
    // localStorage.setItem('paid_to_username', 'hamzathedss');
    // navigate('/thank-you', {replace: true})
    const productData = {
        product: {
            amount: props.price, // Amount in the smallest currency unit (e.g., cents for USD)
            currencyCode: "USD", // Currency code
            paymentMethod: 'card', // Payment method (update as needed)
            description: props.product_name,
            payerNote: "Sellinbio Order",
            customer: {
                email: props.customer_email,
                name: props.customer_name
            },
            cancelUrl: "",
            returnUrl: "https://sellinb.io/thank-you",
        }
    }
    const createOrder = (orderStatus) => {
        orderService.PayOrderWithTranzak(productData)
            .then(async res => {
                if (res.status === 200) {
                    if (res.data.data) {
                        const orderData = {
                            "order_id": res.data.data.requestId,
                            "seller_id": props.seller_id,
                            "customer_name": props.customer_name,
                            "customer_email": props.customer_email,
                            "product_id": props.product_id,
                            "phone_number": props.phone_number,
                            "amount": props.price,
                            "payment_method": props.payment_method,
                            "product_type": props.product_type,
                            "order_status": orderStatus,
                            "affPercentage": props.affPercentage,
                        }
                        await orderService.createOrder(orderData)
                        window.location.href = res.data.data.links.paymentAuthUrl
                    }
                }
            })
            .catch(error => console.log(error))
    };

    return (
        <div>
            <button type="button"
                    onClick={() => handlePayClick(props.payment_method)}
                    disabled={props.disabled}
                    className={`main-button-color w-100 border-0 text-white rounded-3 text-center py-3 mt-2 fw-bold-500 mb-4 ${props.disabled ? 'disabled' : ''}`}>
                Pay Now
            </button>
        </div>
    );
};

export default CheckoutPayHolder;