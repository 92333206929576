import React, {useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {useNavigate} from "react-router-dom";
import {accountService, userSettingService} from "../../../_services";
import {ThreeDots} from "react-loader-spinner";

const StripePaymentForm = ({product_name, access_type, price, payment_method}) => {
    const stripe = useStripe();
    const elements = useElements();
    let navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: localStorage.getItem('full_name') || '',
            },
        });

        if (error) {
            setErrorMessage(error.message);
            return;
        }

        const response = await fetch("https://api.sellinb.io/payments/stripe/create-payment-intent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'x-token': accountService.getToken(),
            },
            body: JSON.stringify({
                amount: parseInt(price * 100),
                currency: 'usd',
                description: product_name,
            }),
        });

        const {client_secret} = await response.json();
        const {error: confirmError, paymentIntent} = await stripe.confirmCardPayment(client_secret, {
            payment_method: paymentMethod.id,
        });
        if (confirmError) {
            setErrorMessage(confirmError.message);
            return;
        }

        if (paymentIntent.status === "succeeded") {
            const planData = {
                subscription_id: paymentIntent.id,
                plan: product_name,
                access_type: access_type,
                payment_method: payment_method,
                price: price,
                status: "paid",
            }
            try {
                await accountService.upgradeUser(planData)
                    .then(res => {
                        if (res.data.status === 200) {
                            accountService.saveToken(res.data.jwt);
                            localStorage.setItem('SELLINBIO_SUBSCRIPTION_ID', paymentIntent.id);
                            navigate('/user/thank-you', {replace: true});
                        }
                    })
                    .catch(error => console.log(error))
            } catch (error) {
                console.error("Error: ", error);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {errorMessage && <div className="text-center small text-danger">{errorMessage}</div>}
            <CardElement className="form-control py-3"/>
            <div className="pay-button text-center">
                <button className="border-0 pay-btn py-2 h-auto small px-5 mt-2" type="submit"
                        disabled={!stripe}>Pay
                </button>
            </div>
        </form>
    );
};

const UpgradeStripePayment = (props) => {
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        const fetchStripePromiseKey = async () => {
            try {
                const data = await userSettingService.getStripePromiseKey();
                setStripePromise(loadStripe(data.promise_key));
            } catch (error) {
                console.error("Error fetching Stripe Promise Key:", error);
            }
        };

        fetchStripePromiseKey();
    }, []);

    return stripePromise ? (
        <Elements stripe={stripePromise}>
            <StripePaymentForm {...props} />
        </Elements>
    ) : (
        <div className="d-flex justify-content-center">
            <ThreeDots
                height={100}
                width={100}
                radius={5}
                color="#683BD1"
                ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    );
};

export default UpgradeStripePayment;