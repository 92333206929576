import React, {useEffect, useState} from "react";
import {userSettingService} from "../../../_services";
import PayPaymentMethods from "./PayPaymentMethods";
import {ThreeDots} from "react-loader-spinner";

const GetReadyToPay = ({product_name, access_type, price}) => {
    const [clientId, setClientId] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const data1 = await userSettingService.getPaypalClientId();
                setClientId(data1.client_id)
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, []);

    useEffect(() => {
        localStorage.removeItem('SELLINBIO_SUBSCRIPTION_ID');
    }, []);

    return (
        <div className="book-content-holder bottom-padding p-3">
            {loading ? (
                <div className="d-flex align-items-center justify-content-center">
                    <ThreeDots
                        height={100}
                        width={100}
                        radius={5}
                        color="#683BD1"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            ) : (
                <PayPaymentMethods
                    client_id={clientId}
                    product_name={product_name}
                    access_type={access_type}
                    price={price}
                />
            )}
        </div>
    );
};

export default GetReadyToPay;