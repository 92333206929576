import Axios from "../ServiceCaller";

let getUserOrders = async () => {
    const { data } = await Axios.get("/user-orders");
    return data.Orders;
};

let PayOrderWithTranzak = (data) => {
    return Axios.post('/payments/tranzak/create-tranzak-order', data);
};

let verifyTransaction = async (transactionId) => {
    const {data} = await Axios.get(`/payments/tranzak/verify-transaction/${transactionId}`);
    return data.Response;
};

let verifySubscriptionTransaction = async (transactionId) => {
    const {data} = await Axios.get(`/payments/tranzak/verify-subscription-transaction/${transactionId}`);
    return data.Response;
};

export const userOrderService = {
    getUserOrders,
    PayOrderWithTranzak,
    verifyTransaction,
    verifySubscriptionTransaction,
};