import {useState} from 'react';
import {PayPalScriptProvider} from "@paypal/react-paypal-js";

import {Tab, Tabs} from "react-bootstrap";

import UpgradePaypalPaymentButton from "./UpgradePaypalPaymentButton";
import UpgradeStripePayment from "./UpgradeStripePayment";
import UpgradeTranzakPayment from "./UpgradeTranzakPayment";

import paypalLogo from "../../../assets/images/global/paypal_logo.png";
import stripeLogo from "../../../assets/images/global/stripe_logo.png";
import tranzakLogo from "../../../assets/images/global/tranzak_logo.png";

const PayPaymentMethods = ({client_id, product_name, access_type, price}) => {
    const [activeTab, setActiveTab] = useState("tranzak");

    function CustomTabPaypal() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1">
                    <img src={paypalLogo} alt="Paypal" className="logo-holder" height="20"/>
                </div>
            </div>
        );
    }

    function CustomTabStripe() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1">
                    <img src={stripeLogo} alt="Stripe" className="logo-holder" height="20"/>
                </div>
            </div>
        );
    }

    function CustomTabTranzak() {
        return (
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center px-1">
                    <img src={tranzakLogo} alt="Tranzak" className="logo-holder" height="20"/>
                </div>
            </div>
        );
    }

    const onTabChange = (tabKey) => {
        setActiveTab(tabKey);
    };

    const initialOptions = {
        clientId: `${client_id}`,
        currency: "USD",
        intent: "capture"
    };

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <Tabs
                variant="pills"
                activeKey={activeTab}
                onSelect={(tabKey) => onTabChange(tabKey)}
                className="mb-3"
            >
                <Tab eventKey="paypal" title={<CustomTabPaypal/>}/>
                <Tab eventKey="stripe" title={<CustomTabStripe/>}/>
                <Tab eventKey="tranzak" title={<CustomTabTranzak/>}/>
            </Tabs>
            {activeTab === 'paypal' && (
                <div className="w-75">
                    <PayPalScriptProvider options={initialOptions}>
                        <UpgradePaypalPaymentButton
                            product_name={product_name}
                            access_type={access_type}
                            price={price}
                            payment_method='paypal'
                        />
                    </PayPalScriptProvider>
                </div>
            )}
            {activeTab === 'stripe' && (
                <div className="w-75">
                    <UpgradeStripePayment
                        product_name={product_name}
                        access_type={access_type}
                        price={price}
                        payment_method='stripe'
                    />
                </div>
            )}
            {activeTab === 'tranzak' && (
                <div className="w-75">
                    <UpgradeTranzakPayment
                        product_name={product_name}
                        access_type={access_type}
                        price={price}
                        payment_method='tranzak'
                    />
                </div>
            )}
        </div>
    );
};

export default PayPaymentMethods;