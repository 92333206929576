import {Footer, Header} from "./Private";
import {useEffect, useId, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useQuery} from "react-query";
import {accountService, userOrderService} from "../../_services";
import {FaCheck} from "react-icons/fa";

const UserThankYou = () => {
    let randomID = useId();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const transactionId = queryParams.get('transactionId');
    const navigate = useNavigate();
    const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
    const [caseType, setCaseType] = useState('');
    const [subscriptionId, setSubscriptionId] = useState('');

    useEffect(() => {
        const storedSubscriptionId = localStorage.getItem('SELLINBIO_SUBSCRIPTION_ID');

        if (transactionId) {
            setCaseType('url');
        } else if (storedSubscriptionId) {
            setCaseType('localStorage');
            setSubscriptionId(storedSubscriptionId)
            setSubscriptionSuccess(true);
        } else {
            navigate('/user/dashboard', {replace: true});
        }
    }, [transactionId]);

    const verifySubscriptionTransaction = () => {
        return userOrderService.verifySubscriptionTransaction(transactionId).then(res => {
            if (res && res.status === 'subscription_status_success') {
                setSubscriptionId(transactionId)
                setSubscriptionSuccess(true);
                accountService.saveToken(res.jwt);
            } else {
                navigate('/user/dashboard', {replace: true});
            }
        });
    };

    useQuery(randomID, verifySubscriptionTransaction, {
        enabled: caseType === 'url'
    });

    return (
        <>
            <Header title="Thank you!"/>
            <div className="the-main-content d-flex flex-column position-relative py-3 px-4 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="main-user-thank-you-page row bg-white py-4 rounded-3">
                    {subscriptionSuccess && (
                        <div className="thank-you-content bottom-padding">
                            <div className="text-center mt-3 px-3">
                                <div className="circle-holder mx-auto">
                                    <div className="circle-1 rounded-circle p-3">
                                        <div className="circle-2 rounded-circle p-3">
                                            <div className="circle-3 rounded-circle p-3">
                                                <FaCheck size="25" color="#fff"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fw-bold text-center main-text-dark-color mt-3 mb-2">
                                    Great!
                                </div>
                                <div className="black-text-color fw-bold-500 lh-sm mb-2">
                                    Payment information<br/>confirmed
                                </div>
                                <div className="gray-text-color small">
                                    Congratulations!
                                </div>
                                <div className="gray-text-color small mt-1">
                                    Your account is <span className="fw-bold main-text-dark-color">Upgraded</span> successfully
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <Link to="/user/dashboard" className="link-as-button py-2 px-5 d-inline-block rounded-3">
                                    Go to Dashboard
                                </Link>
                            </div>
                            {/*<SubscriptionSuccessData*/}
                            {/*    subscription_id={subscriptionId}*/}
                            {/*/>*/}
                        </div>
                    )}
                </div>
                {/*End Page Content*/}
                <Footer/>
            </div>
        </>
    );
};

export default UserThankYou;